// this is a validator for date ranges, if from is provided the date must be after from, if to is provided the date must be before to
import dayjs, { Dayjs } from "dayjs";

export default ({ from, to }: { from?: Dayjs; to?: Dayjs }) =>
  (_: any, value: string) => {
    if (value) {
      const date = dayjs(value);

      if (from && to) {
        if (date && date < from) {
          return Promise.reject(`La fecha debe de ser mayor o igual a ${from}`);
        }
        if (date && date > to) {
          return Promise.reject(`La fecha debe de ser menor o igual a ${to}`);
        }
      }
      if (from) {
        if (date && date < from) {
          return Promise.reject(`La fecha debe de ser mayor o igual a ${from}`);
        }
      }
      if (to) {
        if (date && date > to) {
          return Promise.reject(`La fecha debe de ser menor o igual a ${to}`);
        }
      }
    }
    return Promise.resolve();
  };
