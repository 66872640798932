import React from "react";
import { Card, Input, Tag, Typography } from "antd";

import LoadingBox from "../../LoadingBox";

import useCommonChatList from "./useCommonChatList";

const { Title } = Typography;

const CommonChatListContainer = ({
  children,
  isLoading,
}: {
  children: React.ReactNode;
  isLoading?: boolean;
}) => {
  const { setSearchInput, searchInput, onlyUnread, setOnlyUnread } =
    useCommonChatList();
  return (
    <Card
      title={
        <div
          style={{
            height: 150,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "100%" }}>
            <Title level={3}>Chats</Title>
            <Input
              style={{
                marginBottom: 5,
              }}
              placeholder="Busca por nombre (s) o apellido (s)"
              allowClear
              value={searchInput}
              onChange={(e) => setSearchInput?.(e.target.value)}
            />
            <div style={{ marginTop: 10 }}>
              <Tag
                style={{
                  cursor: "pointer",
                }}
                color="default"
                onClick={() => setOnlyUnread?.(!onlyUnread)}
              >
                {!onlyUnread ? "Mostrar solo no leídos" : "Mostrar todos"}
              </Tag>
            </div>
          </div>
        </div>
      }
      bordered={false}
      styles={{
        body: {
          padding: 0,
          paddingLeft: 0,
          paddingRight: 0,
          height: "calc(100vh - 330px)",
          overflowY: "auto",
          boxShadow: "none",
          borderBottom: "1px solid #f0f0f0",
        },
      }}
    >
      {isLoading ? <LoadingBox /> : children}
    </Card>
  );
};

export default CommonChatListContainer;
