import antdFormAlphabeticWithDiacriticsAndHyphenAndApostropheValidator from "./antdFormAlphabeticWithDiacriticsAndHyphenAndApostropheValidator";
import antdFormAlphabeticWithDiacriticsAndPunctuationValidator from "./antdFormAlphabeticWithDiacriticsAndPunctuationValidator";
import antdFormAlphanumericValidator from "./antdFormAlphanumericValidator";
import antdFormAlphanumericWithDiacriticsOrHyphenOrApostropheValidator from "./antdFormAlphanumericWithDiacriticsOrHyphenOrApostropheValidator";
import antdFormAlphanumericWithDiacriticsOrPunctuationOrNewlineValidator from "./antdFormAlphanumericWithDiacriticsOrPunctuationOrNewlineValidator";
import antdFormAlphanumericWithDiacriticsOrPunctuationOrOtherSymbolValidator from "./antdFormAlphanumericWithDiacriticsOrPunctuationOrOtherSymbolValidator";
import antdFormAlphanumericWithDiacriticsOrPunctuationValidator from "./antdFormAlphanumericWithDiacriticsOrPunctuationValidator";
import antdFormAlphanumericWithDiacriticsValidator from "./antdFormAlphanumericWithDiacriticsValidator";
import antdFormDateIsWithinLastHundredYearsValidator from "./antdFormDateIsWithinLastHundredYearsValidator";
import antdFormDateRangeValidator from "./antdFormDateRangeValidator";
import antdFormEmailValidator from "./antdFormEmailValidator";
import antdFormLengthValidator from "./antdFormLengthValidator";
import antdFormMaxNewLinesValidator from "./antdFormMaxNewLinesValidator";
import antdFormPasswordValidator from "./antdFormPasswordValidator";
import antdFormPhoneValidation from "./antdFormPhoneValidation";
import antdFormUrlValidator from "./antdFormUrlValidator";

// TODO: Correct all validator names to 'Or' instead of 'And' in the name
export {
  antdFormAlphabeticWithDiacriticsAndHyphenAndApostropheValidator,
  antdFormAlphabeticWithDiacriticsAndPunctuationValidator,
  antdFormAlphanumericValidator,
  antdFormAlphanumericWithDiacriticsOrHyphenOrApostropheValidator,
  antdFormAlphanumericWithDiacriticsOrPunctuationOrNewlineValidator, // this is a correct name
  antdFormAlphanumericWithDiacriticsOrPunctuationOrOtherSymbolValidator, // this is a correct name
  antdFormAlphanumericWithDiacriticsOrPunctuationValidator, // this is a correct name
  antdFormAlphanumericWithDiacriticsValidator,
  antdFormDateIsWithinLastHundredYearsValidator,
  antdFormDateRangeValidator,
  antdFormEmailValidator,
  antdFormLengthValidator,
  antdFormMaxNewLinesValidator,
  antdFormPasswordValidator,
  antdFormPhoneValidation,
  antdFormUrlValidator,
};
