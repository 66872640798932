import React, { ReactNode } from "react";
import { ExtendedChat } from "@koble/hooks/src/ChatWebSocket/ChatWebSocketContext";

import CommonChatListContext from "./CommonChatListContext";

const CommonChatListProvider = ({
  children,
  onSelectChat,
  chats,
  viewerUserId,
  selectedChatId,
}: {
  children: ReactNode;
  chats: ExtendedChat[];
  viewerUserId: string;
  selectedChatId?: string;
  onSelectChat: (chatId?: string) => void;
}) => {
  const [searchInput, setSearchInput] = React.useState("");
  const [onlyUnread, setOnlyUnread] = React.useState(false);

  return (
    <CommonChatListContext.Provider
      value={{
        chats,
        viewerUserId,
        onSelectChat,
        selectedChatId,
        searchInput,
        setSearchInput,
        onlyUnread,
        setOnlyUnread,
      }}
    >
      {children}
    </CommonChatListContext.Provider>
  );
};

export default CommonChatListProvider;
