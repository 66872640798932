"use client";

import { createContext } from "react";
import { ExtendedChat } from "@koble/hooks/src/ChatWebSocket/ChatWebSocketContext";

export interface CommonChatListContextProps {
  chats: ExtendedChat[];
  viewerUserId: string;
  selectedChatId?: string;
  onSelectChat: (chatId?: string) => void;
  searchInput: string;
  setSearchInput?: (searchInput: string) => void;
  onlyUnread: boolean;
  setOnlyUnread: (onlyUnread: boolean) => void;
}

const CommonChatListContext = createContext<CommonChatListContextProps>({
  chats: [],
  viewerUserId: "",
  onSelectChat: () => {},
  searchInput: "",
  setSearchInput: () => {},
  onlyUnread: false,
  setOnlyUnread: () => {},
});

export default CommonChatListContext;
