import { UserOutlined } from "@ant-design/icons";
import { ExtendedChat } from "@koble/hooks/src/ChatWebSocket/ChatWebSocketContext";
import { Avatar, Badge, Flex, List, theme, Typography } from "antd";

import useCommonChatList from "./useCommonChatList";

const { Text } = Typography;
const CommonChatListItemEmptyConversation = ({
  chat,
}: {
  chat: ExtendedChat;
}) => {
  const { selectedChatId, onSelectChat, viewerUserId } = useCommonChatList();

  const {
    token: { colorPrimary },
  } = theme.useToken();

  let title = "";

  for (const user of chat.allUsers) {
    if (user.id !== viewerUserId) {
      if (title !== "") {
        title += ", ";
      }
      title += `${user.firstName} ${user.lastName}`;
    }
  }

  const viewerProfileImageUrl = chat.allUsers.find(
    (user) => user.id === viewerUserId
  )?.profileImageUrl;

  const nonViewerProfileImageUrl = chat.allUsers.find(
    (user) => user.id !== viewerUserId
  )?.profileImageUrl;

  return (
    <List.Item
      onClick={() => {
        onSelectChat(chat.chatId);
      }}
      style={{
        cursor: "pointer",
        paddingRight: 15,
        paddingLeft: 15,
        backgroundColor:
          selectedChatId === chat.chatId ? colorPrimary : "white",
      }}
    >
      <List.Item.Meta
        avatar={
          <div style={{ marginTop: 3 }}>
            <Avatar
              style={{
                backgroundColor: "#F5F5F5",
              }}
              src={nonViewerProfileImageUrl}
              size="large"
            >
              <UserOutlined style={{ fontSize: 14, color: "#606060" }} />
            </Avatar>
            <Avatar
              src={viewerProfileImageUrl}
              style={{
                marginLeft: -15,
                marginBottom: -15,
                backgroundColor: "#F5F5F5",
              }}
              size="small"
            >
              <UserOutlined style={{ fontSize: 14, color: "#606060" }} />
            </Avatar>
          </div>
        }
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontWeight: 500,
                color: selectedChatId === chat.chatId ? "white" : "#676767",
              }}
            >
              {title}
            </Text>
            <Text
              style={{
                color: "#676767",
                fontWeight: 500,
                fontSize: "0.8rem",
              }}
            />
          </div>
        }
        description={
          <Flex
            style={{
              ...(chat.unreadMessagesCount && { columnGap: 10 }),
            }}
            align="center"
            justify="space-between"
          >
            <div
              style={{
                color: selectedChatId === chat.chatId ? "white" : "inherit",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3,
              }}
            >
              Conversación vacía! Empieza a chatear!
            </div>
            <Badge
              style={
                selectedChatId === chat.chatId
                  ? { color: "black", backgroundColor: "white" }
                  : { backgroundColor: colorPrimary }
              }
              count={" "}
            />
          </Flex>
        }
      />
    </List.Item>
  );
};

export default CommonChatListItemEmptyConversation;
