import { splitIntoNewlines } from "../textProcessing";

export default (max: number) => (_: any, value: string) => {
  if (value) {
    const lines = splitIntoNewlines(value);
    if (lines.length > max)
      return Promise.reject(`Solo se permiten ${max} renglones como máximo`);
  }

  return Promise.resolve();
};
