export default (_: any, value: string) => {
  const regex = new RegExp(
    "^" + // Start of string
      "[" +
      "\\p{L}" + // Matches any kind of letter from any language
      "\\p{M}" + // Matches a character intended to be combined with another diacritic character (e.g., accents, umlauts)
      "\\p{P}" + // Matches any kind of punctuation character
      "\\d" + // Matches digits (0-9)
      " " + // Matches space characters
      "]*" + // The '*' allows matching zero or more of the above characters
      "$", // End of string
    "gu" // 'g' for global search, 'u' for Unicode support
  );

  // Validates the input string against the regex
  if (value && !value.match(regex)) {
    return Promise.reject(
      new Error(
        "Solo se permiten caracteres alfabéticos, de puntuación y dígitos"
      )
    );
  }

  return Promise.resolve();
};
