"use client";

import { useContext } from "react";

import CommonChatConversationContext from "./CommonChatConversationContext";

const useCommonChatConversation = () =>
  useContext(CommonChatConversationContext);

export default useCommonChatConversation;
