"use client";

import { createContext } from "react";
import { CompatibilityScore, Match } from "@koble/graphql";
import { ExtendedChat } from "@koble/hooks/src/ChatWebSocket/ChatWebSocketContext";

export interface CommonChatConversationContextProps {
  chat: ExtendedChat;
  match?: Match;
  compatibilityScore?: CompatibilityScore;
  viewerUserId: string;
  onSendMessage: (message: string) => void;
  onSelectChat: (chatId?: string) => void;
  jobLinkPrefix?: string;
  userStudentLinkPrefix?: string;
  userRecruiterLinkPrefix?: string;
  businessLinkPrefix?: string;
}

const CommonChatConversationContext =
  createContext<CommonChatConversationContextProps>({
    chat: {} as ExtendedChat,
    viewerUserId: "",
    onSendMessage: () => {},
    onSelectChat: () => {},
  });

export default CommonChatConversationContext;
