import { useEffect, useRef } from "react";
import { SendOutlined } from "@ant-design/icons";
import {
  ProForm,
  ProFormInstance,
  ProFormTextArea,
} from "@ant-design/pro-components";
import { assets } from "@koble/assets/assets";
import { CompatibilityScore, Match } from "@koble/graphql";
import { ExtendedChat } from "@koble/hooks/src/ChatWebSocket/ChatWebSocketContext";
import {
  antdFormLengthValidator,
  antdFormMaxNewLinesValidator,
} from "@koble/utils/src/antdFormValidators";
import { Button, Typography } from "antd";

import CommonChatConversationContainer from "./CommonChatConversationContainer";
import CommonChatConversationMessageList from "./CommonChatConversationMessageList";
import CommonChatConversationProvider from "./CommonChatConversationProvider";
import useCommonChatConversation from "./useCommonChatConversation";

const CommonChatConversation = ({ mobile = false }: { mobile?: boolean }) => {
  const { chat, onSendMessage } = useCommonChatConversation();

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<
    ProFormInstance<{
      message: string;
    }>
  >();

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chat]); // Empty dependency array means this effect will only run once

  return (
    <div
      style={{
        overflow: "auto",
        // maxHeight: "calc(100vh - 80px)",
        width: "100%",
        height: "100%",
        position: "relative",
        borderRight: "1px solid #e8e8e8",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          flexGrow: 1,
          paddingLeft: mobile ? 0 : 30,
          paddingRight: 30,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div
            style={{
              flexGrow: 1,
            }}
          />
          <CommonChatConversationMessageList messagesChat={chat.chatMessages} />
          {chat.isDisabled && (
            <div
              style={{
                minHeight: 200,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                paddingBottom: 30,
                paddingTop: 15,
              }}
            >
              <img
                src={assets.KOBOT_SAD_LARGE}
                style={{
                  width: 100,
                }}
                alt="Sad Kobot"
              />
              <Typography.Title level={4} style={{ marginTop: 10 }}>
                ¡Este chat está deshabilitado!
              </Typography.Title>
              <Typography.Text
                style={{
                  maxWidth: 400,
                  textAlign: "center",
                }}
              >
                Debes tener un match activo para poder intercamabiar mensajes.
                Envía un like para habilitar el chat.
              </Typography.Text>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
      </div>
      <div
        style={{
          position: "sticky",
          bottom: 0,
          left: 0,
          width: "100%",
          zIndex: 100,
          backgroundColor: "white",
          borderTop: "1px solid #e8e8e8",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: 10,
            paddingRight: 5,
            height: 80,
          }}
        >
          <ProForm
            submitter={false}
            style={{ width: "100%" }}
            formRef={formRef}
          >
            <ProFormTextArea
              style={{}}
              placeholder={
                chat.isDisabled
                  ? "Haz match para poder enviar mensajes"
                  : "Escribe un mensaje..."
              }
              disabled={chat.isDisabled}
              rules={[
                {
                  required: true,
                  message: "Por favor escribe un mensaje",
                },
                {
                  validator: antdFormLengthValidator({
                    min: 1,
                    max: 2048,
                  }),
                  message: "El mensaje debe tener entre 1 y de 2048 caracteres",
                },
                {
                  validator: antdFormMaxNewLinesValidator(20),
                },
              ]}
              fieldProps={{
                showCount: true,
                maxLength: 2048,
                autoSize: { minRows: 1, maxRows: 3 },
                onPressEnter: async (e) => {
                  if (!e.shiftKey) {
                    e.preventDefault();

                    try {
                      await formRef.current?.validateFields();
                      const values = formRef.current?.getFieldsValue();
                      if (values?.message) {
                        onSendMessage(values.message);
                        formRef.current?.resetFields();
                      }
                    } catch {
                      /* empty */
                    }
                  }
                },
              }}
              name="message"
              allowClear
            />
          </ProForm>
          <Button
            style={{
              marginLeft: 7,
              marginRight: 3,
            }}
            disabled={chat.isDisabled}
            type="text"
            onClick={async () => {
              await formRef.current?.validateFields();
              const values = formRef.current?.getFieldsValue();
              if (values?.message) {
                onSendMessage(values.message);
                formRef.current?.resetFields();
              }
            }}
            icon={<SendOutlined />}
          />
        </div>
      </div>
    </div>
  );
};

const CommonChatConversationWrapper = ({
  chat,
  match,
  compatibilityScore,
  viewerUserId,
  onSendMessage,
  onSelectChat,
  jobLinkPrefix,
  userStudentLinkPrefix,
  userRecruiterLinkPrefix,
  businessLinkPrefix,
}: {
  chat: ExtendedChat;
  match?: Match;
  compatibilityScore?: CompatibilityScore;
  viewerUserId: string;
  onSendMessage: (message: string) => void;
  onSelectChat: (chatId?: string) => void;
  jobLinkPrefix?: string;
  userStudentLinkPrefix?: string;
  userRecruiterLinkPrefix?: string;
  businessLinkPrefix?: string;
}) => {
  return (
    <CommonChatConversationProvider
      chat={chat}
      match={match}
      compatibilityScore={compatibilityScore}
      viewerUserId={viewerUserId}
      onSendMessage={onSendMessage}
      onSelectChat={onSelectChat}
      jobLinkPrefix={jobLinkPrefix}
      userStudentLinkPrefix={userStudentLinkPrefix}
      userRecruiterLinkPrefix={userRecruiterLinkPrefix}
      businessLinkPrefix={businessLinkPrefix}
    >
      <CommonChatConversationContainer>
        <CommonChatConversation />
      </CommonChatConversationContainer>
    </CommonChatConversationProvider>
  );
};

export default CommonChatConversationWrapper;
