import React, { ReactNode } from "react";

import CommonChatConversationContext, {
  CommonChatConversationContextProps,
} from "./CommonChatConversationContext";

const CommonChatConversationProvider = ({
  children,
  ...props
}: CommonChatConversationContextProps & { children: ReactNode }) => {
  return (
    <CommonChatConversationContext.Provider value={{ ...props }}>
      {children}
    </CommonChatConversationContext.Provider>
  );
};

export default CommonChatConversationProvider;
