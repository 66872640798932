import React, { memo } from "react";
import { ExtendedMessageChat } from "@koble/hooks/src/ChatWebSocket/ChatWebSocketContext";
import { List } from "antd";

import CommonChatConversationMessageCard from "./CommonChatConversationMessageCard";

// eslint-disable-next-line react/display-name
const CommonChatConversationMessageList = memo(
  ({ messagesChat }: { messagesChat: ExtendedMessageChat[] }) => {
    let lastMessageDate: Date | null = null;

    return (
      <List
        itemLayout="horizontal"
        dataSource={messagesChat}
        style={{ paddingTop: 20 }}
        renderItem={(item, e) => {
          if (item.dateTime) {
            const messageDate = new Date(item.dateTime);
            const showDate =
              !lastMessageDate ||
              lastMessageDate.getDate() !== messageDate.getDate();
            lastMessageDate = messageDate;

            // TODO: This is a temporary fix for the date issue:
            // on strict mode items are rendered twice so we need to check if it's the last item and reset the lastMessageDate.
            if (e === messagesChat.length - 1) {
              lastMessageDate = null;
            }
            return (
              <div>
                {showDate && (
                  <div
                    style={{
                      textAlign: "center",
                      color: "#606060",
                      fontSize: 12,
                      marginBottom: 12,
                    }}
                  >
                    {messageDate.toLocaleDateString()}
                  </div>
                )}
                <CommonChatConversationMessageCard messagesChat={item} />
              </div>
            );
          }

          return (
            <div>
              <CommonChatConversationMessageCard messagesChat={item} />
            </div>
          );
        }}
      />
    );
  },
  (prevProps, nextProps) => {
    if (prevProps.messagesChat.length !== nextProps.messagesChat.length)
      return false;

    const prevPropsStatus = prevProps.messagesChat.reduce<{
      [key: number]: "sent" | "sending" | "error" | undefined;
    }>((acc, curr) => {
      acc[curr.chatMessageId] = curr.status;
      return acc;
    }, {});

    const nextPropsStatus = nextProps.messagesChat.reduce<{
      [key: number]: "sent" | "sending" | "error" | undefined;
    }>((acc, curr) => {
      acc[curr.chatMessageId] = curr.status;
      return acc;
    }, {});

    // eslint-disable-next-line no-restricted-syntax
    for (const key in prevPropsStatus) {
      if (prevPropsStatus[key] !== nextPropsStatus[key]) {
        return false;
      }
    }

    return true;
  }
);

export default CommonChatConversationMessageList;
