type LengthValidatorProps = {
  exact?: number;
  min?: number;
  max?: number;
};

type ValidatorParamsType = LengthValidatorProps | number;

// TODO: modify so that it can also be used for when `value` is an array
export default (parameter: ValidatorParamsType) => (_: any, value: string) => {
  if (value) {
    // eslint-disable-next-line prefer-const
    let formattedValue = value.trim();
    formattedValue = formattedValue.replace(/\s+/g, " "); // Replace multiple spaces with a single space
    if (typeof parameter === "number") {
      // If input is a number, it checks against the exact length
      const exact = parameter;
      if (formattedValue.length !== exact) {
        return Promise.reject(
          new Error(
            `El valor debe de estar conformado por exactamente ${exact} caracteres`
          )
        );
      }
    } else {
      // If input is an object, destructure the possible properties
      const { min, max, exact } = parameter;
      if (exact && formattedValue.length !== exact) {
        // Check against exact length
        return Promise.reject(
          new Error(
            `El valor debe de estar conformado por exactamente ${exact} caracteres`
          )
        );
      } else if (min && !max && formattedValue.length < min) {
        // Check against min length only
        return Promise.reject(
          new Error(
            `El valor debe de estar conformado por al menos ${min} caracteres`
          )
        );
      } else if (max && !min && formattedValue.length > max) {
        // Check against max length only
        return Promise.reject(
          new Error(
            `El valor debe de estar conformado por máximo ${max} caracteres`
          )
        );
      } else if (
        min &&
        max &&
        (formattedValue.length < min || formattedValue.length > max)
      ) {
        // Check against a length range
        return Promise.reject(
          new Error(
            `El valor debe de estar conformado por al menos ${min} caracteres y máximo ${max} caracteres`
          )
        );
      }
    }
  }

  return Promise.resolve();
};
