export default (_: any, value: string) => {
  const regex = new RegExp(
    "^" + // Start of string
      "[" +
      "\\p{L}" + // Matches any kind of letter from any language
      "\\d" + // Matches digits (0-9)
      "!" + // Exclamation mark
      '"' + // Double quote
      "#" + // Number sign (hash)
      "$" + // Dollar sign
      "%" + // Percent sign
      "&" + // Ampersand
      "'" + // Single quote
      "(" + // Left parenthesis
      ")" + // Right parenthesis
      "*" + // Asterisk
      "+" + // Plus sign
      "," + // Comma
      "\\-" + // Minus (hyphen)
      "." + // Full stop (period)
      "/" + // Slash
      ":" + // Colon
      ";" + // Semicolon
      "<" + // Less than
      "=" + // Equal sign
      ">" + // Greater than
      "?" + // Question mark
      "@" + // At sign
      "\\[" + // Left square bracket
      "\\\\" + // Backslash
      "\\]" + // Right square bracket
      "\\^" + // Caret
      "_" + // Underscore
      "`" + // Grave accent (backtick)
      "{" + // Left curly brace
      "|" + // Vertical bar
      "}" + // Right curly brace
      "~" + // Tilde
      "]*" + // The '*' allows matching zero or more of the above characters
      "$", // End of string
    "gu" // 'g' for global search, 'u' for Unicode support
  );

  // Validates the input string against the regex
  if (value) {
    if (value.length < 12) {
      return Promise.reject(
        new Error("La contraseña debe tener al menos 12 caracteres")
      );
    }
    if (value.length > 64) {
      return Promise.reject(
        new Error("La contraseña debe tener 64 caracteres como máximo")
      );
    }
    if (!value.match(regex)) {
      return Promise.reject(
        new Error(
          "La contraseña solo puede contener letras, números y caracteres especiales"
        )
      );
    }
  }

  return Promise.resolve();
};
