export default (_: any, value: any) => {
  if (!value) return Promise.reject("Por favor ingresa tu celular");

  if (!value.phoneCountryCode || !value.phoneCountryCode.code)
    return Promise.reject(
      "Por favor selecciona el código de país de tu celular"
    );

  if (!value.phoneNumber) return Promise.reject("Por favor ingresa tu celular");

  const trimmedValue = value.phoneNumber.toString().trim();
  if (trimmedValue.length !== 10) {
    return Promise.reject("El celular debe tener 10 dígitos");
  }

  return Promise.resolve();
};
