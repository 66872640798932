export default (_: any, value: string) => {
  if (!value || value === "") return Promise.resolve();
  try {
    // Add 'http://' as a default protocol if none is provided
    const urlWithProtocol =
      value.includes("http://") || value.includes("https://")
        ? value
        : `http://${value}`;

    const url = new URL(urlWithProtocol);

    // Check for a valid hostname and path, ignore protocol
    if (url.hostname && url.hostname !== "" && url.pathname !== undefined) {
      return Promise.resolve();
    }

    return Promise.reject("Por favor ingresa un URL válido");
  } catch (e) {
    return Promise.reject("Por favor ingresa un URL válido");
  }
};
