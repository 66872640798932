import Highlighter from "react-highlight-words";
import { UserOutlined } from "@ant-design/icons";
import { ExtendedChat } from "@koble/hooks/src/ChatWebSocket/ChatWebSocketContext";
import { timeAgo } from "@koble/utils/src";
import { Avatar, Badge, Flex, List, theme, Typography } from "antd";

import CommonChatListItemEmptyConversation from "./CommonChatListItemEmptyConversation";
import useCommonChatList from "./useCommonChatList";

const { Text } = Typography;
const CommonChatListItem = ({ chat }: { chat: ExtendedChat }) => {
  const { selectedChatId, onSelectChat, viewerUserId, searchInput } =
    useCommonChatList();

  const {
    token: { colorPrimary },
  } = theme.useToken();

  if (chat.chatMessages.length === 0) {
    return <CommonChatListItemEmptyConversation chat={chat} />;
  }

  let title = "";

  for (const user of chat.allUsers) {
    if (user.id !== viewerUserId) {
      if (title !== "") {
        title += ", ";
      }
      title += `${user.firstName} ${user.lastName}`;
    }
  }

  const lastMessage = chat.chatMessages[chat.chatMessages.length - 1];

  let message = lastMessage.message;

  if (lastMessage.from.id === viewerUserId) {
    message = `Tú: ${message}`;
  }

  const viewerProfileImageUrl = chat.allUsers.find(
    (user) => user.id === viewerUserId
  )?.profileImageUrl;

  const nonViewerProfileImageUrl = chat.allUsers.find(
    (user) => user.id !== viewerUserId
  )?.profileImageUrl;

  return (
    <List.Item
      onClick={() => {
        onSelectChat(chat.chatId);
      }}
      style={{
        cursor: "pointer",
        paddingRight: 15,
        paddingLeft: 15,
        backgroundColor:
          selectedChatId === chat.chatId ? colorPrimary : "white",
      }}
    >
      <List.Item.Meta
        avatar={
          <div style={{ marginTop: 3 }}>
            <Avatar
              style={{
                backgroundColor: "#F5F5F5",
              }}
              src={nonViewerProfileImageUrl}
              size="large"
            >
              <UserOutlined style={{ fontSize: 14, color: "#606060" }} />
            </Avatar>
            <Avatar
              src={viewerProfileImageUrl}
              style={{
                marginLeft: -15,
                marginBottom: -15,
                backgroundColor: "#F5F5F5",
              }}
              size="small"
            >
              <UserOutlined style={{ fontSize: 14, color: "#606060" }} />
            </Avatar>
          </div>
        }
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontWeight: 500,
                color: selectedChatId === chat.chatId ? "white" : "#676767",
              }}
            >
              <Highlighter
                highlightClassName="non-viewer-user-highlighted-text"
                searchWords={[searchInput]}
                autoEscape
                textToHighlight={title}
              />
            </Text>
            <Text
              style={{
                color: selectedChatId === chat.chatId ? "white" : "#676767",
                fontWeight: 500,
                fontSize: "0.8rem",
              }}
            >
              <span>{timeAgo.format(new Date(lastMessage.dateTime))}</span>
            </Text>
          </div>
        }
        description={
          <Flex
            style={{
              ...(chat.unreadMessagesCount && { columnGap: 10 }),
            }}
            align="center"
            justify="space-between"
          >
            <div
              style={{
                color: selectedChatId === chat.chatId ? "white" : "inherit",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3,
              }}
            >
              {message.split("\n")[0]}
            </div>
            <Badge
              style={
                selectedChatId === chat.chatId
                  ? { color: "black", backgroundColor: "white" }
                  : { backgroundColor: colorPrimary }
              }
              count={chat.unreadMessagesCount}
            />
          </Flex>
        }
      />
    </List.Item>
  );
};

export default CommonChatListItem;
