import { Fragment } from "react";
import { RiCheckDoubleLine } from "react-icons/ri";
import { TbClockQuestion } from "react-icons/tb";
import { UserOutlined } from "@ant-design/icons";
import { ExtendedMessageChat } from "@koble/hooks/src/ChatWebSocket/ChatWebSocketContext";
import { Avatar, Typography } from "antd";

import useCommonChatConversation from "./useCommonChatConversation";

const { Text } = Typography;
const CommonChatConversationMessageCard = ({
  messagesChat,
}: {
  messagesChat: ExtendedMessageChat;
}) => {
  const { viewerUserId } = useCommonChatConversation();

  const self = messagesChat.from.id === viewerUserId;

  const avatar = (
    <div>
      <Avatar
        src={messagesChat.from?.profileImageUrl}
        style={{
          marginRight: 10,
          marginLeft: 10,
          margin: 5,
          backgroundColor: "#F5F5F5",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <UserOutlined style={{ fontSize: 14, color: "#606060" }} />
      </Avatar>
    </div>
  );

  const messageLines = messagesChat.message
    .split(/(?:\r\n|\r|\n)/g)
    .map((line, index, array) => (
      // For each line, render the line and a <br /> if it's not the last line
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={index}>
        {line}
        {index < array.length - 1 && <br />}
      </Fragment>
    ));

  const textBox1 = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: !self ? "#f8f8f8" : "#f0f9ff",
        borderRadius: 5,
        padding: 10,
        paddingRight: 7,
        maxWidth: "80%",
        alignItems: "end",
      }}
    >
      <Text>
        <span>{messageLines}</span>
      </Text>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          minWidth: 60,
        }}
      >
        <Text
          style={{
            fontSize: 10,
            color: "#999",
            marginLeft: 10,
            marginRight: 2,
            marginTop: 3,
          }}
        >
          {new Date(messagesChat.dateTime).toLocaleTimeString("es-Es", {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </Text>
        {messagesChat.status === "sending" && (
          <TbClockQuestion style={{ fontSize: 15, color: "#999", margin: 0 }} />
        )}
        {messagesChat.status === "sent" && (
          <RiCheckDoubleLine
            style={{ fontSize: 15, color: "#999", margin: 0 }}
          />
        )}
      </div>
    </div>
  );

  const textBox2 = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: !self ? "#f8f8f8" : "#f0f9ff",
        borderRadius: 5,
        padding: 10,
        maxWidth: "80%",
        alignItems: "end",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          minWidth: 35,
        }}
      >
        <Text
          style={{
            fontSize: 10,
            color: "#999",
            marginTop: 3,
          }}
        >
          {new Date(messagesChat.dateTime).toLocaleTimeString("es-Es", {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </Text>
      </div>

      <Text>
        <span>{messageLines}</span>
      </Text>
    </div>
  );

  return (
    <div
      style={{
        marginBottom: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: self ? "flex-end" : "flex-start",
          alignItems: "end",
        }}
      >
        {!self ? (
          <>
            {avatar}
            {textBox2}
          </>
        ) : (
          <>
            {textBox1}
            {avatar}
          </>
        )}
      </div>
    </div>
  );
};

export default CommonChatConversationMessageCard;
