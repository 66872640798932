import React from "react";
import {
  CheckCircleFilled,
  LeftOutlined,
  LoadingOutlined,
  MailOutlined,
  UserOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Card, Space, Tag, Typography } from "antd";

import MobileVisible from "../../MobileVisible";

import useCommonChatConversation from "./useCommonChatConversation";

const { Title, Text } = Typography;
const CommonChatConversationContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    onSelectChat,
    userRecruiterLinkPrefix,
    jobLinkPrefix,
    userStudentLinkPrefix,
    businessLinkPrefix,
    chat,
    viewerUserId,
    match,
    compatibilityScore,
  } = useCommonChatConversation();

  const jobLink = `${jobLinkPrefix}/${chat.job?.jobId}`;
  const viewer = chat.allUsers.find((user) => user.id === viewerUserId);
  const nonViewer = chat.allUsers.find((user) => user.id !== viewerUserId);

  const userLink =
    nonViewer?.type === "userStudent"
      ? `${userStudentLinkPrefix}/${nonViewer.id}`
      : `${userRecruiterLinkPrefix}/${nonViewer?.id}`;

  return (
    <Card
      title={
        <Space wrap style={{ height: 100 }}>
          <MobileVisible>
            <Button
              onClick={() => onSelectChat(undefined)}
              icon={<LeftOutlined />}
              type="text"
              style={{ marginRight: 5 }}
            />
          </MobileVisible>
          <div
            style={{
              display: "flex",
            }}
          >
            <div>
              <Avatar
                src={nonViewer?.profileImageUrl}
                style={{
                  marginRight: 10,
                  backgroundColor: "#F5F5F5",
                  width: chat.job ? 64 : 38,
                  height: chat.job ? 64 : 38,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <UserOutlined
                  style={{
                    fontSize: 14,
                    color: "#606060",
                  }}
                />
              </Avatar>
            </div>
            <div>
              <Avatar
                src={viewer?.profileImageUrl}
                style={{
                  marginRight: 10,
                  marginLeft: chat.job ? -35 : -25,
                  marginBottom: chat.job ? -70 : -25,
                  backgroundColor: "#F5F5F5",
                }}
                size={chat.job ? "default" : "small"}
              >
                <UserOutlined style={{ fontSize: 14, color: "#606060" }} />
              </Avatar>
            </div>
          </div>
          <Space size={0} direction="vertical">
            <Space wrap>
              <a href={userLink}>
                <Title level={5} style={{ margin: 0 }}>
                  {nonViewer?.firstName} {nonViewer?.lastName}
                </Title>
              </a>
              {match !== undefined && (
                <Tag
                  style={{
                    display: "inline-block",
                    fontWeight: "normal",
                    backgroundColor: "white",
                  }}
                >
                  Match el{" "}
                  {new Date(match.createdAt).toLocaleDateString("es-ES")}
                </Tag>
              )}
            </Space>

            {chat.job != null && (
              <Space size={4} wrap>
                <Text style={{ fontWeight: "normal" }}>Vacante de </Text>
                <Text>
                  <a href={jobLink}>
                    <span style={{ fontWeight: 600, color: "black" }}>
                      {chat.job.title}
                    </span>
                  </a>
                </Text>
                <Text style={{ fontWeight: "normal" }}>en</Text>
                <Text>
                  <a
                    href={`${businessLinkPrefix}/${chat.job.business?.businessId}`}
                  >
                    <span style={{ fontWeight: 600, color: "black" }}>
                      {chat.job.business?.name}
                    </span>
                  </a>
                </Text>
              </Space>
            )}

            {match !== undefined && (
              <Space size={8} wrap>
                {nonViewer?.email && (
                  <Text style={{ fontWeight: "normal" }}>
                    <MailOutlined style={{ marginRight: 4 }} />
                    {nonViewer?.email}
                  </Text>
                )}

                {nonViewer?.phone && (
                  <Text style={{ color: "#53c41b", fontWeight: "normal" }}>
                    <WhatsAppOutlined style={{ marginRight: 5 }} />+
                    {nonViewer?.phone?.phoneCountryCode?.code}{" "}
                    {nonViewer?.phone?.phoneNumber}
                  </Text>
                )}

                {compatibilityScore && (
                  <Text style={{ fontWeight: "normal" }}>
                    {compatibilityScore.score === null ? (
                      <>
                        <LoadingOutlined style={{ marginRight: 5 }} />
                        Calculando compatibilidad...
                      </>
                    ) : (
                      <>
                        <CheckCircleFilled style={{ marginRight: 5 }} />
                        {compatibilityScore.score}% compatibilidad
                      </>
                    )}
                  </Text>
                )}
              </Space>
            )}
          </Space>
        </Space>
      }
      bordered={false}
      styles={{
        body: {
          padding: 0,
          height: "calc(100vh - 280px)",
          overflowY: "auto",
          boxShadow: "none",
          borderBottom: "1px solid #e8e8e8",
        },
      }}
    >
      {children}
    </Card>
  );
};

export default CommonChatConversationContainer;
