export default (_: any, value: string) => {
  const regex = new RegExp(
    "^" + // Start of string
      "[" +
      "\\p{L}" + // Matches any kind of letter from any language
      "\\p{M}" + // Matches a character intended to be combined with another diacritic character (e.g., accents, umlauts)
      "\\-' " + // Matches hyphen, apostrophe, and space characters
      "]*" + // The '*' allows matching zero or more of the above characters
      "$", // End of string
    "gu" // 'g' for global search, 'u' for Unicode support
  );

  // Validates the input string against the regex
  if (value && !value.match(regex)) {
    return Promise.reject(
      new Error("Solo se permiten letras, espacios, apóstrofos y guiones.")
    );
  }

  return Promise.resolve();
};
