"use client";

import React, { useEffect, useMemo, useRef, useState } from "react";
import { ExtendedChat } from "@koble/hooks/src/ChatWebSocket/ChatWebSocketContext";
import { Empty, List } from "antd";

import CommonChatListContainer from "./CommonChatListContainer";
import CommonChatListItem from "./CommonChatListItem";
import CommonChatListProvider from "./CommonChatListProvider";
import useCommonChatList from "./useCommonChatList";

const CommonChatList = ({
  isLoading,
  showHeader = false,
}: {
  isLoading: boolean;
  showHeader?: boolean;
}) => {
  const { chats, searchInput, selectedChatId, onlyUnread } =
    useCommonChatList();
  const messagesEndRef = useRef<any>(null);

  const filteredChats = useMemo(() => {
    return chats
      .filter((chat) => {
        const names = chat.allUsers
          .flatMap((userStudent) => {
            return `${userStudent.firstName} ${userStudent.lastName}`;
          })
          .join(" ")
          .toLowerCase();

        return names.includes(searchInput.toLowerCase());
      })
      .filter((chat) => {
        if (onlyUnread && chat.unreadMessagesCount != null) {
          return chat.unreadMessagesCount > 0;
        }
        return true;
      });
  }, [searchInput, chats, onlyUnread]);

  const empty = (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description="No se han encontrado chats"
    />
  );

  return (
    <>
      {showHeader ? (
        <CommonChatListContainer isLoading={isLoading}>
          {filteredChats.length === 0 ? (
            empty
          ) : (
            <List
              itemLayout="horizontal"
              dataSource={filteredChats}
              renderItem={(item) => (
                <div
                  ref={
                    item.chatId === selectedChatId ? messagesEndRef : undefined
                  }
                >
                  <CommonChatListItem chat={item} />
                </div>
              )}
            />
          )}
        </CommonChatListContainer>
      ) : (
        <>
          {filteredChats.length === 0 ? (
            empty
          ) : (
            <List
              itemLayout="horizontal"
              {...(!showHeader && {
                style: { maxHeight: "50vh", overflowY: "auto" },
              })}
              dataSource={filteredChats}
              renderItem={(item) => (
                <div
                  ref={
                    item.chatId === selectedChatId ? messagesEndRef : undefined
                  }
                >
                  <CommonChatListItem chat={item} />
                </div>
              )}
            />
          )}
        </>
      )}
    </>
  );
};

const CommonChatListWrapper = (props: {
  chats: ExtendedChat[];
  viewerUserId: string;
  selectedChatId?: string;
  onSelectChat: (chatId?: string) => void;
  isLoading: boolean;
  showHeader?: boolean;
}) => {
  const { chats, selectedChatId, viewerUserId, onSelectChat, ...rest } = props;

  // State to trigger rerender
  const [, setTimestamp] = useState(Date.now());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimestamp(Date.now());
    }, 60000); // 60000 ms = 1 minute

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <CommonChatListProvider
      chats={chats}
      viewerUserId={viewerUserId}
      onSelectChat={onSelectChat}
      selectedChatId={selectedChatId}
    >
      <CommonChatList {...rest} />
    </CommonChatListProvider>
  );
};

export default CommonChatListWrapper;
