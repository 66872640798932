export default (_: any, value: string) => {
  const regex = new RegExp(
    "^" + // Start of the string
      "[\\w\\.-]+" + // Matches one or more word characters (letters, digits, or underscores) or hyphens or dots before the `@` symbol
      "@" + // Matches the `@` symbol
      "([\\w-]+\\.)+" + // Group that matches one or more fragments of word characters (letters, digits, or underscores) or hyphens, followed by a dot, before the domain
      "[\\w-]{2,4}" + // Matches between 2 and 4 word characters (letters, digits, or underscores) or hyphens representing the domain extension
      "$" // End of the string
  );

  // Validates the input string against the regex
  if (value && !value.match(regex)) {
    return Promise.reject(new Error("El correo no es válido"));
  }

  return Promise.resolve();
};
